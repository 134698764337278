import React from 'react';
import pageStyle from '../../assets/css/page.module.css';
import style from '../../assets/css/main.module.css';
import Menu from '../../components/menu';
import Footer from '../../components/footer';
import HeroPage from '../../components/hero.page';
import Hamburger from '../../components/hamburger';

import imgSEO from '../../assets/img/services/referencement.jpg';
import imgBlock1 from '../../assets/img/blocks/website01.jpg';
import imgBlock3 from '../../assets/img/blocks/content.jpg';
import imgBlock4 from '../../assets/img/blocks/optimisation.jpg';
import imgBlock2 from '../../assets/img/heroes/seo.jpg';
import SimilarCard from '../../components/similar.card';
import similar1 from '../../assets/img/services/thumbnail/seo.jpg';
import similar3 from '../../assets/img/services/thumbnail/contenu.jpg';
import similar2 from '../../assets/img/services/thumbnail/pub.jpg';
import similar4 from '../../assets/img/services/thumbnail/maintenance.jpg';
import SEO from '../../components/seo';
import CtaSection from '../../components/cta.section';
import cta from '../../assets/img/illustrations/search.svg';
import AniLink from 'gatsby-plugin-transition-link/AniLink';

const boxStyle1 = {
  backgroundImage: 'url(' + imgBlock1 + ')',
  backgroundPosition: 'center'
};
const boxStyle2 = {
  backgroundImage: 'url(' + imgBlock2 + ')'
};
const boxStyle3 = {
  backgroundImage: 'url(' + imgBlock3 + ')',
  backgroundPosition: 'center'
};

const boxStyle4 = {
  backgroundImage: 'url(' + imgBlock4 + ')',
  backgroundPosition: 'center'
};

export default () => (
  <div id="top">
    <Menu />
    <SEO
      title="Référencement | Agence digitale | MDS Digital Agency"
      description="Nous utilisons les dernières innovations en matière de référencement pour rendre votre site internet visible sur les moteurs de recherche."
      keywords="référencement"
    />
    <div className={style.menu_mobile}>
      <Hamburger />
    </div>
    <HeroPage img={imgSEO} />

    <article className={pageStyle.article}>
      {/* <h2>Référencement</h2> */}
      <div className={pageStyle.intro_container} style={{ marginTop: '3em' }}>
        <h4 className={pageStyle.intro}>
          Internet est un média incontournable, l'importance d'avoir un site web est donc cruciale
          pour toute entreprise. Internet est un canal de diffusion efficace pour communiquer à
          l'international à moindre coût. Un site web est en effet accessible partout dans le monde.
        </h4>
      </div>

      <div style={{ marginBottom: 0 }} className={pageStyle.box_container_white}>
        <div style={boxStyle1} className={pageStyle.box_panel_left}></div>
        <div style={{ justifyContent: 'start' }} className={pageStyle.box_panel_right}>
          <div className={pageStyle.box_content}>
            <h5>Création de site internet responsive</h5>
            <p>Le succès de votre entreprise commence avec votre site internet.</p>
            <p>Nous le réalisons sur mesure ou à partir d’un thème prédéfini ?</p>
            <div style={{ textAlign: 'left' }} className={pageStyle.cta}>
              <AniLink className={pageStyle.box_content_cta_text}>En savoir plus</AniLink>
            </div>
          </div>
        </div>
      </div>

      <div style={{ marginTop: 0, marginBottom: 0 }} className={pageStyle.box_container_white}>
        <div style={{ justifyContent: 'end' }} className={pageStyle.box_panel_right}>
          <div style={{ textAlign: 'right' }} className={pageStyle.box_content}>
            <h5>Référencement de votre site internet</h5>
            <p style={{ textAlign: 'right' }}>
              Gagnez en visibilité sur les moteurs de recherches.
            </p>
            <p style={{ textAlign: 'right' }}>
              Nous vous proposons des solutions de référencement naturel et de référencement payant
              afin d’augmenter votre visibilité.
            </p>
            <div style={{ textAlign: 'right' }} className={pageStyle.cta}>
              <AniLink className={pageStyle.box_content_cta_text}>En savoir plus</AniLink>
            </div>
          </div>
        </div>
        <div style={boxStyle2} className={pageStyle.box_panel_left}></div>
      </div>

      <div style={{ marginTop: 0, marginBottom: 0 }} className={pageStyle.box_container_white}>
        <div style={boxStyle3} className={pageStyle.box_panel_left}></div>
        <div style={{ justifyContent: 'start' }} className={pageStyle.box_panel_right}>
          <div className={pageStyle.box_content}>
            <h5>Gestion de contenu</h5>
            <p>Disposez d’un site internet mis à jour continuellement.</p>
            <p>Nous nous chargeons de mettre à jour votre site internet.</p>
            <div style={{ textAlign: 'left' }} className={pageStyle.cta}>
              <AniLink className={pageStyle.box_content_cta_text}>En savoir plus</AniLink>
            </div>
          </div>
        </div>
      </div>

      <div style={{ marginTop: 0, marginBottom: 0 }} className={pageStyle.box_container_white}>
        <div style={{ justifyContent: 'end' }} className={pageStyle.box_panel_right}>
          <div style={{ textAlign: 'right' }} className={pageStyle.box_content}>
            <h5>Optimisation de votre site internet</h5>
            <p style={{ textAlign: 'right' }}>
              Disposez d’un site internet avec de meilleures performances.{' '}
            </p>
            <p style={{ textAlign: 'right' }}>
              Nous réalisons un audit technique et nous mettons en place les améliorations à
              effectuer sur votre site.
            </p>
            <div style={{ textAlign: 'right' }} className={pageStyle.cta}>
              <AniLink className={pageStyle.box_content_cta_text}>En savoir plus</AniLink>
            </div>
          </div>
        </div>
        <div style={boxStyle4} className={pageStyle.box_panel_left}></div>
      </div>
      <CtaSection
        img={cta}
        ctaText="Devis en ligne"
        title="A la recherche d’une stratégie de référencement sur mesure ?"
        subtitle="Bénéficiez d’une plus grande visibilité sur le web grâce à notre forte expertise."
      />
    </article>
    <div className={pageStyle.footer}>
      <div className={pageStyle.article}>
        <h3>Autres services</h3>
        <div className={pageStyle.footer_flex}>
          <SimilarCard
            text="Référencement naturel (SEO)"
            url="/services/referencement/naturel/"
            img={similar1}
          />
          <SimilarCard
            text="Référencement payant (SEA)"
            url="/services/referencement/payant/"
            img={similar2}
          />
          <SimilarCard
            text="Mise à jour de contenu"
            url="/services/mise-a-jour-contenu/"
            img={similar3}
          />
          <SimilarCard text="Maintenance" url="/services/maintenance/" img={similar4} />
        </div>
      </div>
    </div>
    <Footer />
  </div>
);
